body {
  margin: 0;
  font-family: Work Sans;
}

.home-container .swiper {
  width: 100%;
  height: 100%;
  text-align: center;
}

.home-container .swiper-slide img {
  width: 100%;
  height: 100%;
}

.otp-input {
  text-align: center !important;
  font: normal normal 28px/34px Work Sans !important;
  letter-spacing: -0.67px !important;
  color: #1a281f !important;
  border-bottom: 1px solid #eaeaea !important;
}

.main-container {
  margin-top: 120px;
}

.custom-scrollbar {
  overflow-y: scroll;
}

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(148, 147, 147);
}

.wpwl-form-card {
  background-color: #ffffff !important;
  background-color: #ffffff !important;
  box-shadow: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
}
