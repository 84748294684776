

.swiper {
    width: 650px;
    height: 450px;
  }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-button-prev{
  margin-top: 6% !important
}
.swiper-button-next{
  margin-top: 6% !important
}

.mostly-customized-scrollbar {
  display: block;
  width: 100%;
  overflow: auto;
  height: 650px;
}

.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #aaa; /* or add it to the track */
}

.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  background: gray;
}

/* .fieldsetText{
  display: none;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  display: none
} */