#Loading-Fall {
  text-align: center;
}

#Loading-Fall h1 {
  margin: 0;
  padding: 0;
  font-family: ‘Arial Narrow’, sans-serif;
  font-weight: 100;
  font-size: 2.1em;
}

#Loading-Fall span {
  position: relative;
  top: 0.63em;
  display: inline-block;
  text-transform: uppercase;
  opacity: 0;
  transform: rotateX(-90deg);
}

#Loading-Fall .let1 {
  animation: loadingDrop 1.2s ease-in-out infinite;
  animation-delay: 1.2s;
}

#Loading-Fall .let2 {
  animation: loadingDrop 1.2s ease-in-out infinite;
  animation-delay: 1.3s;
}

#Loading-Fall .let3 {
  animation: loadingDrop 1.2s ease-in-out infinite;
  animation-delay: 1.4s;
}

#Loading-Fall .let4 {
  animation: loadingDrop 1.2s ease-in-out infinite;
  animation-delay: 1.5s;
}

#Loading-Fall .let5 {
  animation: loadingDrop 1.2s ease-in-out infinite;
  animation-delay: 1.6s;
}

#Loading-Fall .let6 {
  animation: loadingDrop 1.2s ease-in-out infinite;
  animation-delay: 1.7s;
}

#Loading-Fall .let7 {
  animation: loadingDrop 1.2s ease-in-out infinite;
  animation-delay: 1.8s;
}

@keyframes loadingDrop {
  10% {
    opacity: 0.5;
  }
  20% {
    opacity: 1;
    top: 3.78em;
    transform: rotateX(-360deg);
  }
  80% {
    opacity: 1;
    top: 3.78em;
    transform: rotateX(-360deg);
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    top: 6.94em;
  }
}
